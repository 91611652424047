import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Confirmation } from './components/Confirmation';
import { Empty } from './components/Empty';
import { Header } from './components/header/Header';
import Home from './Home';
import { Value } from './utils/constants';
import { Inputs, ResponseModel, Status, Via } from './utils/types';


const App: React.FC = () => {

    const { control, register, handleSubmit, setError, clearErrors, resetField, formState: { errors } } = useForm<Inputs>({ mode: "onBlur" });
    const [ endtype, setEndtype ] = useState<string>("");
    const [ reasonEndtype, setReasonEndtype ] = useState<string>("");
    const [ date, setDate ] = useState<Date>(new Date());
    const [ redirect, setRedirect ] = useState(false);
    const [ empty, setEmpty ] = useState(false);
    const [ zresponse, setZresponse ] = useState<ResponseModel>({id: 0});


    let brand_id: number;
    let brand_txt: string;

    // Todo: get the default language form the query param e.g: lang=de
    const brand = (new URLSearchParams(window.location.search)).get(Value.BRAND_KEY);

   brand_id = brand === Value.BULLGUARD ? 360002653978 : 360000078937;
		// brand_id = brand === Value.BULLGUARD ? 360002904497 : 114095288974;
    brand_txt = brand === Value.BULLGUARD ? 'Bullguard' : 'Avira';

    const [ formdata, setFormdata ] = useState<Inputs>({lang:"en", recaptcha: false, brand: brand_id, brandtext: brand_txt});

    return (
      <>
      <Header formdata={formdata} setFormdata={setFormdata} />
      <Router>
          <Switch>
            <Route path="/confirm">
              <Confirmation
                zresponse={zresponse}
                formdata={formdata}
                />
            </Route>
            <Route path="/empty">
              <Empty
                empty={empty}
                />
            </Route>
            <Route path="/">
              <Home
                control={control}
                register={register}
                handleSubmit={handleSubmit}
                setError={setError}
                resetField={resetField}
                clearErrors={clearErrors}
                errors={errors}
                endtype={endtype}
                reasonEndtype={reasonEndtype}
                setEndtype={setEndtype}
                setReasonEndtype={setReasonEndtype}
                date={date}
                setDate={setDate}
                redirect={redirect}
                setRedirect={setRedirect}
                formdata={formdata}
                setFormdata={setFormdata}
                setZresponse={setZresponse}
                empty={empty}
                setEmpty={setEmpty}
                />
            </Route>
          </Switch>
      </Router>
      </>
    );
}

export default App;
