export const Value = {
	MESSAGE: "message",
    ASAP: "asap",
    EOC: "eoc",
    SPECIFIC: "specific",
    SITE_KEY: '6Lf0GU4gAAAAALAVgODXG6tNXpzYMPMMxA24a-mC',
    // SITE_KEY: '6LfY9GwgAAAAADBAoWdYiTCDpwdZhI2tHM8bsKjB',
    CONVENIENCE: 'convenience',
    OTHERS: 'others',
    BRAND_KEY: "brand",
    BULLGUARD: "bullguard",
    AVIRA: "avira",
    Avira: "Avira",
    LANG: "lang"
}
