import Creatable from 'react-select/creatable';
import Select from 'react-select';
import './App.css';
import ReCAPTCHA from "react-google-recaptcha";
import Flatpickr  from "react-flatpickr";
import { German } from "flatpickr/dist/l10n/de";
import { english } from "flatpickr/dist/l10n/default";
import { Trans, useTranslation } from 'react-i18next';
import { Inputs, ResponseModel } from './utils/types';
import { Value } from './utils/constants';
import { verifyCaptcha } from './utils/recaptcha';
import { Controller } from 'react-hook-form';
import { Redirect, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { generateNote } from './utils/confirmnote';
import { Svg } from './components/Svg';


interface props {
    control: any;
    register: any;
    handleSubmit: any;
    resetField: any;
    setError: any;
    clearErrors: any;
    errors: any;
    reasonEndtype: string;
    endtype: string;
    setEndtype: React.Dispatch<React.SetStateAction<string>>;
    setReasonEndtype: React.Dispatch<React.SetStateAction<string>>;
    date: Date;
    setDate: React.Dispatch<React.SetStateAction<Date>>;
    redirect: boolean;
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>;
    formdata: Inputs;
    setFormdata: React.Dispatch<React.SetStateAction<Inputs>>;
    setZresponse: React.Dispatch<React.SetStateAction<ResponseModel>>;
    empty: boolean;
    setEmpty: React.Dispatch<React.SetStateAction<boolean>>;
}

const Home: React.FC<props> = ({
    control,
    register,
    handleSubmit,
    resetField,
    setError,
    clearErrors,
    errors,
    endtype,
    reasonEndtype,
    setEndtype,
    setReasonEndtype,
    date,
    redirect,
    setRedirect,
    formdata,
    setFormdata,
    setZresponse,
    empty,
    setEmpty
}) => {

    const EMPTY_FIELD_COUNT = 8;
    const { t, i18n } = useTranslation();
    const brandtext = formdata.brandtext;
    const [ serverError, setServerError ] = useState<boolean>(false);
    const [load, setLoad] = useState<boolean>(false);
    const { search } = useLocation();
    const [csrfToken, setCsrfToken] = useState('');
    
    const createTicket = async (formdata: Inputs) => {

        let myProm = new Promise<Inputs>((resolve, reject) => {

            let emptyCount = 0;
            
            Object.values(formdata).some((v) => {
                emptyCount = (v === null || typeof v === "undefined" || v === '') ? emptyCount + 1 : emptyCount;
            });

            if(emptyCount === EMPTY_FIELD_COUNT) {
                reject("Field Empty");
            } else {
                setLoad(true);
                resolve(formdata);
            }
        });

        myProm.then(callZendesk, () => { setEmpty(true)});  
    }

    const callZendesk = (formdata: Inputs) => {
        // note for email notification
        formdata.note = generateNote(formdata, t);  

        const zResponse = axios
            .post("/api/tickets", formdata, { headers: {'xsrf-token': csrfToken} })
            .then(response => {
                setZresponse(response.data as ResponseModel);
                setFormdata(formdata);
                setRedirect(true);
            })
            .catch(error => {
                setServerError(true);
            });
        
        zResponse.then(() => {setLoad(false); setCsrfToken('') });
    }

    const getCSRFToken = async () => {
        const response = await axios.get('/getCSRFToken');
        setCsrfToken(response.data.CSRFToken);
    };

    useEffect(() => {
      getCSRFToken()
    }, [])

    return (
        <>
        {empty && (
          <Redirect to={`/empty${search}`} />
        )}
        {redirect && (
          <Redirect to={`/confirm${search}`} />
        )}
        <main>
            <section className="page-main-content">
                <header>
                    <h1 className="regular">{t('headline')}</h1>
                </header>
                <form id="cancellation" className="cancellation-form" accept-charset="UTF-8" onSubmit={handleSubmit(createTicket)}>
                    <Trans i18nKey="text-above-form">
                        <p>Please fill out the form below to cancel your contract with us. Please make sure all the information you've entered is correct so that we're able to identify you and the contract you'd like to cancel. </p>
                    </Trans>
                    {/* <div className="form-group d-flex flex-justify-between note-are"> */}
                    <Trans i18nKey="text-below-form">
                        <p>Please note that cancelling your contract means your paid subscription will be terminated. If applicable, you will also be issued a refund according to our <a href={brandtext === Value.Avira ? t('avira-refund-policy-link') : t('bullguard-refund-policy-link') }>Cancellation & Refund Policy</a></p>
                    </Trans>
                    {/* </div> */}
                    {serverError && (
                        <Trans i18nKey="error-above-form">
                            <p className='err-txt'>Sorry, we couldn't process your request at this time. Contact Support.</p>
                        </Trans>
                    )}
                    <div id="step-2" className="bg-gray checkfields checkfields--step-2">
                        <div className= "form-group d-flex flex-justify-between">
                            <div className="form-field half text optional">
                                <label htmlFor="fname">{t('firstname')}<span className='err-txt'>*</span></label>
                                <input
                                    className={`form-input text ${errors.firstname && 'error' }`}
                                    placeholder={t('firstname-placeholder')}
                                    {...register("firstname", {
                                        required: true,
                                        minLength: {
                                            value: 2,
                                            message: t('firstname-too-short')
                                        },
                                        pattern: {
                                            value: /^([A-Za-z\u00C0-\u00FF])+$/i,
                                            message: t('text-not-valid')
                                        }
                                    })
                                    }
                                />
                                {errors.firstname && <span className="err-txt">{errors.firstname.message}</span>}
                            </div>

                            <div className="form-field half text optional">
                                <label htmlFor="lname">{t('lastname')}<span className='err-txt'>*</span></label>
                                <input
                                    className={`form-input text ${errors.lastname && 'error' }`}
                                    placeholder={t('lastname-placeholder')}
                                    {...register("lastname", {
                                        required: true,
                                        minLength: {
                                            value: 2,
                                            message: t('lastname-too-short')
                                        },
                                        pattern: {
                                            value: /^([A-Za-z\u00C0-\u00FF])+$/i,
                                            message: t('text-not-valid')
                                        }
                                    })
                                    }
                                />
                                {errors.lastname && <span className="err-txt">{errors.lastname.message}</span>}
                            </div>
                        </div>

                        <div className="form-group d-flex flex-justify-between">
                            <div className="form-field full text email optional">
                                <Trans i18nKey="email-label" values={{ brandtext }}>
                                    <label htmlFor="remail">Registered {brandtext} Email Address<span className='err-txt'>*</span></label>
                                </Trans>
                                <input
                                    className={`form-input text ${errors.email && 'error' }`}
                                    placeholder={t('email-placeholder')}
                                    {...register("email", {
                                        required: true,
                                        pattern: {
                                            value: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
                                            message: t('email-not-valid')
                                        }
                                    })
                                    }
                                />
                                {errors.email && <span className="err-txt">{errors.email.message}</span>}
                            </div>
                        </div>

                        <div className="form-group d-flex flex-justify-between">
                            <div className="form-field full text email optional">
                                <Trans i18nKey="reference-label" values={{ brandtext }}>
                                    <label htmlFor="referenceno">{brandtext} contract reference number</label>
                                </Trans>
                                <input
                                    className={`form-input number-text ${errors.referenceno && 'error' }`}
                                    placeholder={t('reference-placeholder')}
                                    {...register("referenceno", {
                                        required: false,
                                        minLength: {
                                            value: 8,
                                            message: t('reference-not-valid')
                                        }, 
                                        maxLength: {
                                            value: 10,
                                            message: t('reference-not-valid')
                                        } 
                                    })
                                    }
                                />
                               {errors.referenceno && <span className="err-txt">{errors.referenceno.message}</span>}

                            </div>
                        </div>

                        <div className="form-group d-flex flex-justify-between">
                            <div className={`form-field ${reasonEndtype.length === 0 ? 'full' : 'half' } text select optional custom-select" aria-required`}>
                                <label htmlFor="reason">{t('reason-label')}</label>
                                <Controller
                                    control={control}
                                    name="reasons"
                                    render={({ field: { onChange, value, ref }}) => (
                                        <Creatable
                                        options={[
                                            { value: Value.CONVENIENCE, label: t('reason-convenience') as string },
                                            { value: Value.OTHERS, label: t('reason-others') as string}
                                        ]}
                                        className="select-input form-input"
                                        value={[
                                            { value: Value.CONVENIENCE, label: t('reason-convenience') },
                                            { value: Value.OTHERS, label: t('reason-others') }
                                        ].find(c => c.value === value)}
                                        onChange={val => {
                                            onChange(val?.value);
                                            val?.value === Value.OTHERS ? setReasonEndtype(val?.value as string) : setReasonEndtype('');
                                        }}
                                        placeholder={t('reason-placeholder')}
                                        // styles={customStyles}
                                        />
                                    )}
                                />
                            </div>

                            <div id="specific-dat" className={`form-field half text select optional custom-select ${reasonEndtype.length === 0 ? 'hidden' : '' }`}>
                                <label htmlFor="enddate">...</label>
                                <input
                                    className='form-input text'
                                    placeholder={t('extraordinary-placeholder')}
                                    { ...register("extraordinary") }
                                />
                            </div>

                        </div>

                        <div className="form-group d-flex flex-justify-between">
                            <div id="inteded-end-date" className={`form-field ${endtype.length === 0 ? 'full' : 'half' } text select optional custom-select`}>
                                <label htmlFor="enddate">{t('cancel-date-label')}</label>
                                <Controller
                                    control={control}
                                    name="contractenddate"
                                    render={({ field: { onChange, value, ref }}) => (
                                        <Select
                                        placeholder={t('cancel-date-placeholder')}
                                        options={[
                                            { value: Value.ASAP, label: t('cancel-asap') as string},
                                            { value: Value.EOC, label: t('cancel-eoc') as string},
                                            { value: Value.SPECIFIC, label: t('cancel-date') as string},
                                        ]}
                                        className="select-input form-input"
                                        value={[
                                            { value: Value.ASAP, label: t('cancel-asap') },
                                            { value: Value.EOC, label: t('cancel-eoc') },
                                            { value: Value.SPECIFIC, label: t('cancel-date') },
                                        ].find(c => c.value === value)}
                                        onChange={val => {
                                            onChange(val?.value);
                                            val?.value === Value.SPECIFIC ? setEndtype(val?.value as string) : setEndtype('');
                                        }}
                                        />
                                    )}
                                />
                            </div>

                            <div id="specific-dat" className={`form-field half text select optional custom-select ${endtype.length === 0 ? 'hidden' : '' }`}>
                                <label htmlFor="sdate">{t('date-label')}</label>

                                <Controller
                                    control={control}
                                    defaultValue={ date.toLocaleString("ro-RO").split(',')[0] }
                                    name="eocDate"
                                    render={({ field: { onChange, value }}) => (
                                        <Flatpickr
                                            value={ date }
                                            options={{ minDate: "today", dateFormat: "d.m.Y", "locale": i18n.resolvedLanguage === 'de' ? German : english }}
                                            onChange={(dateObj, dateStr) => onChange(dateStr) }
                                        />
                                    )
                                }
                                />
                            </div>
                            
                        </div>

                        <div className="form-group d-flex flex-justify-between">
                            <div className="form-field full text email optional">
                                <label htmlFor="confemail">{t('email-confirmation-label')}<span className='err-txt'>*</span></label>
                                <input
                                    className={`form-input text ${errors.confirmationemail && 'error' }`}
                                    placeholder={t('email-confirmation-placeholder')}
                                    {...register("confirmationemail", { 
                                        required: true,
                                        pattern: {
                                            value: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
                                            message: t('email-confirmation-not-valid')
                                        }
                                    })
                                    }
                                />
                                {errors.confirmationemail && <span className="err-txt">{errors.confirmationemail.message}</span>}
                            </div>
                        </div>

                        <div className="form-group d-flex flex-justify-between">
                            <div id="recaptcha" className="form-field full custom-select recaptcha">
                                <Controller
                                    control={control}
                                    name="recaptcha"
                                    {...register("recaptcha", {required: true})}
                                    render={({ field: { onChange, value }}) => (
                                        <ReCAPTCHA
                                            sitekey={Value.SITE_KEY}
                                            onChange={val => verifyCaptcha(val, clearErrors, setError, resetField, setServerError) }
                                            hl={i18n.resolvedLanguage}
                                        />
                                    )
                                }
                                />
                                {errors.recaptcha && <span className="err-txt">{t('recaptcha-error')}</span>}
                            </div>
                        </div>

                        <div className="form-group d-flex flex-justify-between submit-area">
                            <input hidden={true} defaultValue={i18n.resolvedLanguage} {...register("lang")} />
                            <input hidden={true} defaultValue={formdata.brand} {...register("brand")} />
                            <input hidden={true} defaultValue={formdata.brandtext} {...register("brandtext")} />
                            <input hidden={true} defaultValue={t('title')} {...register("title")} />
                            <input type="submit" disabled={load} className="button btn btn-primary" value={t('cancel-now')} />
                            <Svg display={load}/>
                        </div>

                        <div>

                        </div>

                    </div>
                </form>
            </section>
        </main>
        {/* <I18n /> */}
        </>
    );
}

export default Home;
