import { TFunction } from "i18next";
import { Value } from "./constants";
import { Inputs, Note } from "./types";

export function generateNote(formdata: Inputs, t: TFunction): Note {

    const date = new Date().toLocaleDateString('en-DE').replaceAll('/','.');
    const time = new Date().toLocaleTimeString('en-DE').replaceAll('/','.');
    const note = formdata.contractenddate === Value.SPECIFIC ? t('cancel-date-note', { specificdate: formdata.eocDate }) : 
                    formdata.contractenddate === Value.EOC ? t('cancel-eoc-note') : 
                    t('cancel-asap-note');
    
    let data: Note = { thankyou: t('hello') };

    data.notice = t("para1-display", { date: date, time: time });

    data.display = {
        [t('firstname')]: formdata.firstname,
        [t('lastname')]: formdata.lastname,
        ['Email']: formdata.email,
        [t('reference')]: formdata.referenceno,
        [t('reason-label')]: formdata.reasons === Value.OTHERS ? formdata.extraordinary : formdata.reasons === Value.CONVENIENCE ? t('reason-convenience') : '',
        [t('cancel-date-label')]: formdata.contractenddate === Value.SPECIFIC ? formdata.eocDate : formdata.contractenddate === Value.EOC ? t('cancel-eoc') : t('cancel-asap'),
        [t('email-confirmation-label')]: formdata.confirmationemail
    };

    data.close = t("para3-display", { note: note });
    data.extra = t("para4-display", { linker: formdata.brandtext === Value.Avira ? t('avira-refund-policy-link') : t('bullguard-refund-policy-link') , 
        brandtext: formdata.brandtext });

    return data;
}