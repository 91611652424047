import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // On development: Enable StrictMode in order to detect any problems with your code and warn you about them. Else your component will be rendered twice
  // <React.StrictMode>
    <React.Suspense fallback="loading...">
        <App />
    </React.Suspense>
  // </React.StrictMode>
);
